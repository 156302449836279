@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-smooth: never;
  -webkit-font-smoothing: never;
  -moz-osx-font-smoothing: never;
}

code {
  font-family: 'Press Start 2P', cursive;
  font-smooth: never;
  -webkit-font-smoothing: never;
  -moz-osx-font-smoothing: never;
}

